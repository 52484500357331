<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="2" fill="#2C98F0"/>
    <path d="M12 11.5C12 10.7777 11.8577 10.0625 11.5813 9.39524C11.3049 8.72795 10.8998 8.12163 10.3891 7.61091C9.87836 7.10019 9.27205 6.69506 8.60476 6.41866C7.93747 6.14226 7.22227 6 6.5 6C5.77773 6 5.06253 6.14226 4.39524 6.41866C3.72795 6.69506 3.12163 7.10019 2.61091 7.61091C2.10019 8.12164 1.69506 8.72795 1.41866 9.39524C1.14226 10.0625 1 10.7777 1 11.5L6.5 11.5H12Z" fill="#2C98F0"/>
    <path d="M23 13.0476V12C23 11.7107 22.7655 11.4762 22.4762 11.4762H16.3518C18.9578 9.65386 19.593 6.06406 17.7707 3.45811C16.6925 1.91628 14.929 0.998496 13.0476 1H12C11.7107 1 11.4762 1.23454 11.4762 1.52381V7.64819C9.65386 5.04224 6.06406 4.40699 3.45811 6.22932C1.91628 7.30752 0.998496 9.07099 1 10.9524V12C1 12.2893 1.23454 12.5238 1.52381 12.5238H7.64819C6.11179 13.6027 5.19526 15.3608 5.19048 17.2381C5.19421 20.4187 7.77174 22.9963 10.9524 23H12C12.2893 23 12.5238 22.7655 12.5238 22.4762V16.3518C14.3461 18.9578 17.9359 19.593 20.5419 17.7707C22.0837 16.6925 23.0015 14.9291 23 13.0476ZM12.5238 2.04762H13.0476C15.6512 2.05122 17.759 4.16486 17.7554 6.76845C17.7525 8.80935 16.4368 10.6166 14.4954 11.2462C14.2203 10.3262 13.4637 9.63048 12.5238 9.43334V2.04762ZM2.04762 11.4762V10.9524C2.0524 8.34879 4.16695 6.24202 6.77061 6.2468C8.81007 6.25054 10.6158 7.56536 11.2457 9.5051C10.326 9.78029 9.63055 10.5367 9.43333 11.4762H2.04762ZM11.4762 21.9524H10.9524C8.34879 21.9488 6.24104 19.8351 6.24464 17.2315C6.24746 15.1907 7.5632 13.3834 9.50457 12.7538C9.7797 13.6738 10.5363 14.3695 11.4762 14.5667V21.9524ZM12 13.5714C11.1321 13.5714 10.4286 12.8679 10.4286 12C10.4286 11.1321 11.1321 10.4286 12 10.4286C12.8679 10.4286 13.5714 11.1321 13.5714 12C13.5714 12.8679 12.8678 13.5714 12 13.5714ZM17.2294 17.7532C15.1899 17.7495 13.3842 16.4346 12.7543 14.4949C13.674 14.2197 14.3695 13.4633 14.5667 12.5238H21.9524V13.0476C21.9475 15.6513 19.833 17.758 17.2294 17.7532Z" fill="white"/>
  </svg>
</template>

<script>
  export default {
    name: "Fun2"
  }
</script>