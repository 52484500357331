<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.9" d="M4.54235 2.29337C5.82837 1.25014 7.32999 0.476901 8.92374 0.0303806C8.92438 5.02064 8.92374 10.0104 8.92374 15.0006C13.9494 15.0012 18.9749 15 24 15.0006C23.437 17.0795 22.3061 18.9992 20.7684 20.5038C19.3359 21.9139 17.5505 22.96 15.6209 23.5183C13.4917 24.1368 11.1937 24.1597 9.05192 23.587C7.04341 23.0499 5.17733 21.9896 3.68942 20.5347C1.96976 18.8651 0.756406 16.6771 0.264428 14.3282C-0.210385 12.0881 -0.0444831 9.71393 0.751831 7.5662C1.50924 5.509 2.83414 3.66502 4.54235 2.29337ZM15.3881 0C17.5357 0.572051 19.5138 1.75398 21.0487 3.36295C22.4297 4.80683 23.4502 6.59521 23.9839 8.52287C21.119 8.52401 18.2536 8.52344 15.3881 8.52344C15.3881 5.6821 15.3881 2.84134 15.3881 0Z" fill="#868CA8"/>
  </svg>
</template>

<script>
  export default {
    name: "HeaderLogo"
  }
</script>