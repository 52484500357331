<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.99 9.00004C19.15 5.16004 13.8 3.76004 8.84 4.78004L11.36 7.30004C14.83 7.13004 18.35 8.35004 20.99 11L22.99 9.00004V9.00004ZM18.99 13C17.7 11.71 16.15 10.87 14.5 10.44L18.03 13.97L18.99 13V13ZM2 3.05004L5.07 6.10004C3.6 6.82004 2.22 7.78004 1 9.00004L2.99 11C4.23 9.76004 5.66 8.84004 7.19 8.23004L9.43 10.47C7.81 10.89 6.27 11.73 5 13V13.01L6.99 15C8.35 13.64 10.13 12.96 11.91 12.94L18.98 20L20.25 18.74L3.29 1.79004L2 3.05004ZM9 17L12 20L15 17C13.35 15.34 10.66 15.34 9 17Z" fill="#818288"/>
  </svg>
</template>

<script>
  export default {
    name: "Wifi0"
  }
</script>