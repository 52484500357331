<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.8594 13.0781C16.2969 12.0781 17.0156 10.7188 17.0156 9C17.0156 7.625 16.5156 6.45312 15.5156 5.48438C14.5469 4.48438 13.375 3.98438 12 3.98438C10.625 3.98438 9.4375 4.48438 8.4375 5.48438C7.46875 6.45312 6.98438 7.625 6.98438 9C6.98438 10.7188 7.70312 12.0781 9.14062 13.0781L9.98438 13.6875V15.9844H14.0156V13.6875L14.8594 13.0781ZM7.03125 4.07812C8.40625 2.70313 10.0625 2.01562 12 2.01562C13.9375 2.01562 15.5781 2.70313 16.9219 4.07812C18.2969 5.42188 18.9844 7.0625 18.9844 9C18.9844 11.4375 17.9844 13.3437 15.9844 14.7187V17.0156C15.9844 17.2969 15.8906 17.5312 15.7031 17.7188C15.5156 17.9062 15.2812 18 15 18H9C8.71875 18 8.48437 17.9062 8.29687 17.7188C8.10937 17.5312 8.01562 17.2969 8.01562 17.0156V14.7187C6.01563 13.3437 5.01562 11.4375 5.01562 9C5.01562 7.0625 5.6875 5.42188 7.03125 4.07812ZM9 21V20.0156H15V21C15 21.2812 14.9062 21.5156 14.7188 21.7031C14.5312 21.8906 14.2969 21.9844 14.0156 21.9844H9.98438C9.70312 21.9844 9.46875 21.8906 9.28125 21.7031C9.09375 21.5156 9 21.2812 9 21Z" fill="white"/>
  </svg>
</template>

<script>
  export default {
    name: "Lamp"
  }
</script>