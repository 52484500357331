<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 12.0476V11C22 10.7107 21.7655 10.4762 21.4762 10.4762H15.3518C17.9578 8.65386 18.593 5.06406 16.7707 2.45811C15.6925 0.916276 13.929 -0.0015041 12.0476 1.85042e-06H11C10.7107 1.85042e-06 10.4762 0.234537 10.4762 0.523811V6.64819C8.65386 4.04224 5.06406 3.40699 2.45811 5.22932C0.916276 6.30752 -0.0015041 8.07099 1.85042e-06 9.95238V11C1.85042e-06 11.2893 0.234538 11.5238 0.523811 11.5238H6.64819C5.11179 12.6027 4.19526 14.3608 4.19048 16.2381C4.19421 19.4187 6.77174 21.9963 9.95238 22H11C11.2893 22 11.5238 21.7655 11.5238 21.4762V15.3518C13.3461 17.9578 16.9359 18.593 19.5419 16.7707C21.0837 15.6925 22.0015 13.9291 22 12.0476ZM11.5238 1.04762H12.0476C14.6512 1.05122 16.759 3.16486 16.7554 5.76845C16.7525 7.80935 15.4368 9.61662 13.4954 10.2462C13.2203 9.32617 12.4637 8.63048 11.5238 8.43334V1.04762ZM1.04762 10.4762V9.95238C1.0524 7.34879 3.16695 5.24202 5.77061 5.2468C7.81007 5.25054 9.61583 6.56536 10.2457 8.5051C9.32597 8.78029 8.63055 9.53667 8.43333 10.4762H1.04762ZM10.4762 20.9524H9.95238C7.34879 20.9488 5.24104 18.8351 5.24464 16.2315C5.24746 14.1907 6.5632 12.3834 8.50457 11.7538C8.7797 12.6738 9.53635 13.3695 10.4762 13.5667V20.9524ZM11 12.5714C10.1321 12.5714 9.42857 11.8679 9.42857 11C9.42857 10.1321 10.1321 9.42857 11 9.42857C11.8679 9.42857 12.5714 10.1321 12.5714 11C12.5714 11.8679 11.8678 12.5714 11 12.5714ZM16.2294 16.7532C14.1899 16.7495 12.3842 15.4346 11.7543 13.4949C12.674 13.2197 13.3695 12.4633 13.5667 11.5238H20.9524V12.0476C20.9475 14.6513 18.833 16.758 16.2294 16.7532Z" fill="#828282"/>
  </svg>
</template>

<script>
  export default {
    name: "Fun0"
  }
</script>